.timeline-item {
  position: relative;
  width: 50%;
  margin-left: 50%;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  text-align: left;

  &:nth-child(2n+1) {
    margin-left: 0;
    padding-right: 1.5rem;
    padding-left: 0;
    text-align: right;

    &:before {
      margin-right: -.5rem;
    }

    &:after,
    &:before {
      right: 0;
      left: auto;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: -.1875rem;
    width: .0625rem;
    margin-top: 1.875rem;
    margin-left: -.0625rem;
    background-color: $gray-400;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    margin-top: .5rem;
    margin-left: -.5rem;
    border: .0625rem solid $gray-400;
    border-radius: 50%;
  }
}