$card-height: 200px;
$card-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

.card-title {
  margin-bottom: 1rem;
}

.card-header,
.card-footer {
  border: 0;
}

.card {
  -webkit-box-shadow: $card-shadow;
  -moz-box-shadow: $card-shadow;
  box-shadow: $card-shadow;
  border: 0;

  &.active {
    border: 1px solid $primary;
    color: $primary;
    background-color: tint-color($primary, 90%);
  }
}

.card-item {
  border-top-width: 2px !important;

  .card-header {
    background-color: inherit;
  }

  .card-footer {
    .btn-link:first-child {
      padding-left: 0;
    }
  }
}

.hover {
  &:hover,
  &:focus {
    -webkit-box-shadow: 0 2px 6px 0 $input-btn-focus-color;
    -moz-box-shadow: 0 2px 6px 0 $input-btn-focus-color;
    box-shadow: 0 2px 6px 0 $input-btn-focus-color;
    @include transition(.2s ease);
  }
}