@charset "UTF-8";

@font-face {
  font-family: "main";
  src:url("fonts/main.eot");
  src:url("fonts/main.eot?#iefix") format("embedded-opentype"),
    url("fonts/main.woff") format("woff"),
    url("fonts/main.ttf") format("truetype"),
    url("fonts/main.svg#main") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "main" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "main" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-synthesize:before {
  content: "\65";
}
.icon-construct:before {
  content: "\67";
}
.icon-biomodule:before {
  content: "\68";
}
.icon-backbone:before {
  content: "\69";
}
.icon-facebook:before {
  content: "\61";
}
.icon-linkedin:before {
  content: "\63";
}
.icon-youtube:before {
  content: "\66";
}
.icon-twitter:before {
  content: "\62";
}
.icon-organism:before {
  content: "\6b";
}
.icon-mammalian:before {
  content: "\64";
}
.icon-virus:before {
  content: "\6a";
}
.icon-plant:before {
  content: "\6c";
}
.icon-yeast:before {
  content: "\6d";
}
.icon-bacteria:before {
  content: "\6e";
}
