$blue-200: #5bbce0 !default;
$blue-700: #394F6B !default;

$blue: #0078b6 !default;
$purple: #6477D7 !default;
$pink: #d7176f;
$red: #DA3236 !default;
$orange: #F59E0B !default;
$yellow: #F3C96C !default;
$green: #3EC075 !default;
$teal: #00CFC8 !default;
$sub-teal: #01A2A3 !default;
$dark-teal: #40767A !default;
$dark: #344453 !default;
$almost-dark: #2F5462 !default;
$cyan: #5ebcdd !default;
$cardinal: #A41A55;

$primary: $blue-700 !default;
$success: $green !default;
$info: $sub-teal !default;
$warning: $yellow !default;
$danger: $red !default;
$dark: $dark !default;
