// Fonts
@import "../fonts/main/styles.css";
@import "../fonts/sbol/styles.css";
@import "components/icons";

// See docs:
// https://getbootstrap.com/docs/5.0/customize/sass/#importing

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "Theme";
@import "custom";

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";

// 4. Include any optional Bootstrap components as you like
// Configuration;
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
/*! purgecss start ignore */
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
/*! purgecss end ignore */
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
//@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
//@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

// 5. Add additional custom code here
@import "base";
@import "mixins";
@import "components/card";
/*! purgecss start ignore */
@import "components/autocomplete";
@import "components/badge";
/*! purgecss end ignore */
@import "components/buttons";
@import "components/sequence";
@import "components/forms";
@import "components/sidebar";
@import "components/navs";
@import "components/tables";
@import "components/utils";
@import "components/theme";
@import "components/progress";
@import "components/animations";
@import "components/plasmid";
@import "components/alignment";
@import "components/timeline";
@import "components/alert";

$select-color-dropdown-border-top: $input-border-color;
$select-color-dropdown: $body-bg;
$input-border-width: $border-width;
$select-color-item: $gray-200;
$select-margin-item-x: .5rem;
@import "~tom-select/dist/scss/tom-select.bootstrap5";
@import "~@sjmc11/tourguidejs/src/scss/tour.scss" ;
