.divider {
  margin: 1rem 0;
  height: 0;
  font-weight: 700;
  text-transform: uppercase;
  color: $gray-900;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.horizontal.divider {
  display: table;
  white-space: nowrap;
  height: auto;
  line-height: 1;
  text-align: center;
}

.horizontal.divider:before {
  background-position: right 1em top 50%;
}

.horizontal.divider {
  &:after,
  &:before {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAACCAYAAACuTHuKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1OThBRDY4OUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1OThBRDY4QUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU5OEFENjg3Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU5OEFENjg4Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+VU513gAAADVJREFUeNrs0DENACAQBDBIWLGBJQby/mUcJn5sJXQmOQMAAAAAAJqt+2prAAAAAACg2xdgANk6BEVuJgyMAAAAAElFTkSuQmCC);
  }
}

.horizontal.divider {
  &:after {
    background-position: left 1em top 50%;
  }

  &:before {
    background-position: right 1em top 50%;
  }
}

.scroll {
  overflow-y: scroll !important;
}

.text-normal {
  text-transform: none;
}

.sticky-panel {
  @extend .sticky-top;
  z-index: inherit;
  top: 6em;
}

@media (min-width: 992px) {
  .mb-lg-6 {
    margin-bottom: 6rem !important;
  }
}

.table-p-0 {
  td,
  th {
    padding: 0;
  }
}

.z-index-0 {
  z-index: 499 !important;
}

.z-index-toast {
  z-index: $zindex-toast !important;
}

.md-16 {
  font-size: 16px;
}

.w-20 {
  width: 20%;
}

.list-inline-lg {
  li {
    a {
      font-size: 24px;
    }
  }
}

.rotate-90 {
  transform: rotate(90deg);
}

.border-purple {
  border-color: $purple !important;
}

.text-purple {
  color: $purple;
}

.text-circle-primary {
  display: inline-block;
  position: relative;
  text-align: center;
  white-space: nowrap;
  border: 2px solid $primary;
  color: shade-color($primary, 25%);
  padding: 0;
  line-height: 32px;
  border-radius: 36px;
  height: 36px !important;
  width: 36px !important;
}

.shadow-xs {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.letter-spacing {
  letter-spacing: .05em;
}

.z-index-50 {
  z-index: $zindex-dropdown;
}

.w-12 {
  width: 3rem;
}

.h-1 {
  height: .25rem;
}

.h-5 {
  height: 5em
}

.w-5 {
  width: 5em;
}

.top-10 {
  top: 3.75em;
}

.top-25 {
  top: 6em;
}

.flipped {
  -o-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

.border-striped {
  outline: 3px dashed $gray-400;
  outline-offset: -3px;
}

.collaborators .collaborator:not(:first-child) {
  @extend .ms-n3
}