$plasmid-color-interval: 55%;

text {
  fill: $gray-900;
}

polyline {
  cursor: pointer;
  stroke: $gray-300;
  stroke-width: 2px;
  fill: none;
}

.plasmid,
.sidebar,
.editor {
  circle {
    fill: none;
    stroke-width: 10;
    stroke: $gray-400;
  }
}

.lines {
  path {
    fill: none;
    stroke-width: 30;
    stroke: $gray-400;
  }
}

.stroke-primary-light {
  stroke: color-level("blue-200", -7);
}

.plasmid {
  .slice-labels text,
  .line-labels text {
    cursor: pointer;
    text-anchor: middle;
    font-size: 24px;
    fill: $gray-500;

    &:hover {
      @include transition(.2s);
      fill: tint-color($gray-500, $plasmid-color-interval);
    }
  }

  .slices {
    path,
    rect {
      cursor: pointer;
      stroke-width: 1;
      fill: $gray-400;

      &:hover {
        @include transition(.2s);
      }

      &.orange {
        stroke: $orange;
        fill: tint-color($orange, $plasmid-color-interval);

        &:hover {
          fill: $orange;
        }
      }

      &.warning {
        stroke: $yellow;
        fill: tint-color($yellow, $plasmid-color-interval);

        &:hover {
          fill: $yellow;
        }
      }

      &.danger {
        stroke: $red;
        fill: tint-color($red, $plasmid-color-interval);

        &:hover {
          fill: $red;
        }
      }

      &.success {
        stroke: $green;
        fill: tint-color($green, $plasmid-color-interval);

        &:hover {
          fill: $green;
        }
      }

      &.filled {
        stroke: $blue-200;
        fill: tint-color($blue-200, $plasmid-color-interval);

        &:hover,
        &.active {
          fill: $blue-200;
        }
      }

      &.empty {
        stroke: $gray-500;
        fill: $white;

        &:hover {
          fill: tint-color($teal, $plasmid-color-interval);
        }

        &.active {
          fill: $teal;
        }
      }

      &.readonly {
        stroke: $gray-400;
        fill: tint-color($gray-400, $plasmid-color-interval);

        &:hover,
        &.active {
          fill: $gray-400;
        }
      }
    }
  }

  &.plasmid-primary {
    .slices {
      path,
      rect {
        &.empty {
          &.active {
            fill: $teal;
            stroke: $teal;
          }

          &:hover {
            fill: tint-color($teal, $plasmid-color-interval);
            stroke: $teal;
          }
        }
      }
    }
  }

  &.linear {
    background-size: contain;
    overflow-x: auto;

    .ruler {
      stroke: $gray-400;
    }

    line.line-links {
      cursor: pointer;
      stroke: $gray-300;
      stroke-width: 2px;
      fill: none;
    }

    svg {
      path,
      rect {
        cursor: pointer;
        stroke-width: 2;
      }
    }
  }
}

.no-pointer {
  svg {
    path,
    rect,
    .slices {
      cursor: default !important;
    }
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.fill-primary {
  fill: $primary;
}