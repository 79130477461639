.bg-blue-200 {
  background: $blue-200;
}

.bg-blue-300 {
  background: $blue-300;
}

.bg-blue-500 {
  background: $blue-500;
}

.bg-gray {
  background: $gray-400;
}

.bg-orange {
  background: $orange;
}

.bg-purple {
  background: $purple;
}

.text-purple {
  color: $purple;
}

.bg-dark-teal {
  background: $dark-teal;
}

.text-dark-teal {
  color: $dark-teal;
}

.border-blue-200 {
  border-color: $blue-200 !important;
}

.darken-25 {
  background-color: rgba(0, 0, 0, 0.1);
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
}

.border-gray-200 {
  border-color: $gray-200 !important;
}


.bg-hero {
  background: linear-gradient(225deg, var(--bs-info) 15%, var(--bs-primary) 75%);
  background-size: 180% 180%;
  animation: gradient-animation 15s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}