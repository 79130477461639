$material-symbols-font-size: 18px !default;
$material-symbols-font-path: '~material-symbols/';

@import 'material-symbols/outlined.scss';
@import '~flag-icons/css/flag-icons.min.css';

.material-symbols-outlined, [class^="sbol-"], [class^="icon-"] {
  font-size: $material-symbols-font-size;

  &.small {
    font-size: 16px;
  }
}

.btn-check + .btn {
  .radio-icon:before {
    content: 'radio_button_unchecked';
  }

  .checkbox-icon:before {
    content: 'check_box_outline_blank';
  }
}

.btn-check:checked + .btn {
  .radio-icon:before {
    content: 'radio_button_checked';
  }

  .checkbox-icon:before {
    content: 'check_box';
  }
}

