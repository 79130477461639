#alignment {
  margin: 0;
  padding: 0;

  .basePairQualityValue {
    fill: $warning;
  }

  .wrap-start {
    stroke: $gray-200;
  }

  .info {
    font-size: .8rem;
  }

  #preview, #referenceContent {
    line.reference {
      stroke: $gray-500;
    }

    .tickGroup {
      line {
        stroke: $black;
      }

      text.tick {
        fill: $black;
        font-size: .7em;
      }
    }

    circle.matching-pattern {
      stroke: $red;
    }

    .redundant-reference {
      fill: $gray-400;
    }

  }

  #referenceContent {
    overflow-x: hidden;

    text {
      .basePairSequence {
        font-family: monospace;
        font-size: 1rem;
      }
    }

    .annotation {
      cursor: pointer;
    }
  }

  #readsContainer {
    min-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;

    .basePairSequence {
      font-family: monospace;
    }
  }

  #preview, #referenceContainer, #readsContainer {
    svg {
      .brush {
        cursor: grab;
        fill: $gray-200;
      }

      line.firstEdge, line.secondEdge {
        cursor: col-resize;
        stroke: $black;
      }
    }
  }

  #toolbar {
    [data-editor-zoom], [data-editor-scroll] {
      cursor: pointer;
    }
  }
}


