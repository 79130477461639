
.autoComplete_wrapper {
  @extend .flex-grow-1
}

.autoComplete_wrapper > ul {
  z-index: $zindex-dropdown;
  @extend .mt-2;
  @extend .p-0;
  @extend .position-absolute;
  @extend .shadow;
  @extend .bg-white;
  @extend .rounded;
  width: 100%;
  max-width: 100%;
}

.autoComplete_wrapper > ul > li {
  padding: 10px 20px;
  list-style: none;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.1s ease-in-out;
}

.autoComplete_wrapper > ul > li:hover {
  cursor: pointer;
  background-color: $table-hover-bg;
}
