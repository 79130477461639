$sidebar-width: 220px;
$sidebar-toggled-width: 80px;

#wrapper {
  padding-left: $sidebar-toggled-width;
  @include transition(all 0.5s ease);

  &.toggled {
    padding-left: $sidebar-width;

    #sidebar-wrapper {
      .sidebar {
        width: $sidebar-width;

        .nav-header {
          display: inline;
        }

        .nav-item,
        .navbar-brand {
          display: flex;
          justify-content: left !important;

          span {
            display: inline;
            margin-left: 5px;
          }

          .badge {
            display: flex !important;
          }

          i {
            @include transition(all 0.5s ease);
            opacity: .50;
          }
        }
      }
    }
  }
}

#sidebar-wrapper {
  .sidebar {
    top: 0;
    width: $sidebar-toggled-width;
    @include transition(all 0.5s ease);

    .nav-header {
      display: none;
    }

    .nav-item,
    .navbar-brand {
      display: flex;
      justify-content: center !important;

      span {
        display: none;
      }

      .badge {
        display: none !important;
      }

      i {
        opacity: .50;
        margin-right: 0 !important;
        padding-top: .4rem;
        padding-bottom: .4rem;
        @include transition(all 0.5s ease);
      }
    }
  }
}

.sidebar-dark {
  .nav-item {
    color: $light;

    &:hover,
    &:focus,
    &.active {
      background: rgba(0, 0, 0, 0.1);
    }

    &.active {
      i {
        opacity: 1 !important;
        color: $white;
      }

      border-left: 4px solid $blue-200;
    }
  }

  .nav-item-info {
    color: $dark;
    background: $teal;

    &:hover,
    &:focus,
    &.active {
      background: shade-color($teal, 10%);
    }

    &.active {
      border-left: 0;
    }
  }
}

@media screen and (max-width: map_get($grid-breakpoints, 'md')) {
  #wrapper {
    padding-left: 0 !important;

    #sidebar-wrapper {
      .sidebar {
        width: 0 !important;
      }
    }
  }
}
