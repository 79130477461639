.bg-flash-success {
  animation: flash 2s;
  td {
    animation: flash 2s;
  }

}

@keyframes flash {
  0% {
    background-color: tint-color($green, 50%);
  }
  100% {
    background-color: $white;
  }
}

;