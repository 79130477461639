.btn {
  text-shadow: none !important;
}

.align-icon {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  i {
    vertical-align: middle;

    &:before {
      @extend .d-flex, .align-items-center;
    }
  }

  i:not(:last-child) {
    margin-right: .5em;
  }
}

.btn-outline-danger {
  background-color: $white;
}

.btn-secondary {
  color: $gray-700;
}

@media screen and (max-width: map_get($grid-breakpoints, 'lg')) {
  .align-icon {
    i:not(:last-child) {
      margin: 0;
    }
  }
}


.btn-white {
  @include button-variant(
          $background: $white,
          $border: $gray-100,
          $color: $gray-700,
  );
}

.btn-dark-teal {
  @include button-variant($dark-teal, $dark-teal);
}

.btn-outline-dark-teal {
  @include button-outline-variant($dark-teal, $white);
}


$all-colors: map-merge-multiple($indigos, $blues, $purples, $pinks, $reds, $oranges, $yellows, $greens, $teals, $cyans);

@each $color, $value in $all-colors {
  .btn-#{$color} {
    @if $color == "light" {
      @include button-variant(
                      $value,
                      $value,
              $hover-background: shade-color($value, $btn-hover-bg-shade-amount),
              $hover-border: shade-color($value, $btn-hover-border-shade-amount),
              $active-background: shade-color($value, $btn-active-bg-shade-amount),
              $active-border: shade-color($value, $btn-active-border-shade-amount)
      );
    } @else if $color == "dark" {
      @include button-variant(
                      $value,
                      $value,
              $hover-background: tint-color($value, $btn-hover-bg-tint-amount),
              $hover-border: tint-color($value, $btn-hover-border-tint-amount),
              $active-background: tint-color($value, $btn-active-bg-tint-amount),
              $active-border: tint-color($value, $btn-active-border-tint-amount)
      );
    } @else {
      @include button-variant($value, $value);
    }
  }
}

@each $color, $value in $all-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}