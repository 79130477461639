.sequence textarea, input.sequence {
  font-family: $font-family-monospace;
  text-transform: uppercase;

  &::-webkit-input-placeholder {
    text-transform: none !important;
  }
}

.filter-option-inner-inner {
  text-transform: none !important;
}

.asteriskField {
  font-size: 0.6em;
  vertical-align: super;
}
