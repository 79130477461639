#secondaryPanel {
  #areaTxtToSearch {
    position: relative;
  }

  input#cursorPosition,
  input#fromBp,
  input#toBp {
    width: 6em;
  }
}

#overviewTools {
  #sliderZoom, [data-editor-zoom], #sliderScroll, [data-editor-scroll] {
    cursor: pointer;
  }
}

[data-popper-reference-hidden] {
  visibility: hidden;
  pointer-events: none;
}

#editor, #previewEditor, #plasmid {
  overflow-x: hidden;
  white-space: nowrap;
  padding: 0;

  svg {
    .brush {
      cursor: grab;
      fill: $orange-200;
      opacity: .2;
    }

    #brushMarker {
      circle {
        stroke: black;
        stroke-width: .5;
        fill: #000;
        opacity: 1;
      }
    }

    #lineRuler {
      fill: $gray-800;
      stroke: $gray-800;
    }

    .restrictionEnzymeText {
      font-size: .8em;
    }

    .slot {
      fill: $blue-200;
      stroke: $blue-200;

      &.biomodule-obsolete {
        fill: $danger;
        stroke: $danger;
      }

      &.biomodule-public {
        fill: $green;
        stroke: $green;
      }

      &.biomodule-shared {
        fill: $purple;
        stroke: $purple;
      }

      &.editor {
        stroke: $gray-900;
        stroke-width: 1;
      }
    }


  }

  text {
    display: inline-block;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  svg#circular,
  svg#linear {
    .basePairSequence,
    .basePairComplement,
    .restEnzyme-text {
      font-family: monospace;
    }

    g.ticks {
      fill: none;

      line.ticks-lines {
        fill: $dark;
        stroke: $dark;
      }

      text.ticks {
        font-size: 11px;
      }
    }

    g.others {
      text {
        fill: $dark;
      }
    }

    g.restrictionEnzymeGroup {
      path {
        fill: none;
      }

      .cut_5 {
        stroke: $gray-400;
        stroke-width: 1;
      }

      .cut_line.show {
        fill: none;
        stroke: $gray-800;
        stroke-width: 1;
      }

      .cut_range.show {
        fill: $gray-400;
        opacity: .6;
      }
    }

    /* start - previous version (to delete when circular editor is refactored)*/

    path.cutRE {
      fill: none;
      stroke: $dark;
      stroke-width: 0;
    }

    path.cutRE.show-cut {
      fill: none;
      stroke-width: 1px;
    }

    path.rangeRE,
    rect.rangeRE {
      fill: none;
    }

    .rangeRE.show-range {
      fill: $purple;
      opacity: .5;

    }

    /* end */

    g.searchIndexes path {
      fill: $yellow;
      stroke: $dark;
      stroke-width: 0;
      opacity: .5;
    }

    line.searchIndexes {
      stroke: $yellow;
    }

    rect.area {
      stroke: $dark;
      stroke-width: .1px;
      opacity: 0.5;
    }

    rect#areaSearch {
      fill: $gray-400;
      stroke: $dark;
      stroke-width: .1px;
      opacity: 0.2;
    }
  }
}

#previewEditor, #plasmid {
  .firstEdge, .secondEdge {
    opacity: .5;
  }
}

#editor, #circular, #alignment {
  .firstEdge, .secondEdge {
    cursor: grabbing;
    stroke: $black;
    stroke-width: 2;
    opacity: 1;
  }
}

div.popover.contextMenuStyle {
  .popover-header {
    display: none;
  }

  .popover-body {
    padding: 0;
  }

  .popover-arrow {
    display: none;
  }
}
