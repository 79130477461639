.nav-tabs {
  .nav-link {
    border-width: 0;
    background: none !important;
    opacity: .75;

    i {
      margin-right: .5rem;
      color: $navbar-light-icon-color;
    }

    &.active {
      border-bottom: 4px solid $primary;
    }
  }
}

.nav-link {
  &.active {
    opacity: 1;
    font-weight: bolder;
  }
}

.navbar-main {
  .nav-link {
    &.active {
      color: $primary !important;
    }
  }
}

@media (min-width: 992px) {
  .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@media screen and (max-width: map_get($container-max-widths, 'lg')) {
  .navbar-light,
  .navbar-dark {
    .nav-link {
      padding-top: .5em;
      padding-bottom: .5em;

      &.active,
      &:focus,
      &:hover {
        border-bottom: 0;
      }
    }
  }
}

.navbar-toggler {
  border: 0;

  &:focus {
    outline: none !important;
  }

  &.active {
    background: rgba(0, 0, 0, 0.1);
  }
}

.nav-tabs-vertical {
  &.nav-tabs {
    border-bottom: 0;

    .nav-link {
      margin-left: 4px;

      &.active {
        background: $white !important;
        border-radius: 0;
        border-bottom: 0;
        margin-left: 0;
        border-left: 4px solid $primary;
      }
    }
  }
}

.nav-link {
  i {
    vertical-align: middle;
  }
}

.nav-tabs-purple {
  .nav-link {
    &.active {
      border-color: $purple !important;

      i {
        color: $purple;
      }
    }
  }
}

.nav-tabs-danger {
  .nav-link {
    &.active {
      border-color: $danger !important;

      i {
        color: $danger;
      }
    }
  }
}

.nav-tabs-dark-teal {
  .nav-link {
    &.active {
      border-color: $dark-teal !important;

      i {
        color: $dark-teal;
      }
    }
  }
}
