.list-group-flex {
  .list-group-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    span {
      font-weight: normal;
    }
  }
}

.list-group-rounded {
  .list-group-item {
    &:first-child,
    &:first-child:hover {
      border-top-right-radius: .5rem;
      border-top-left-radius: .5rem;
    }

    &:last-child,
    &:last-child:hover {
      border-bottom-right-radius: .5rem;
      border-bottom-left-radius: .5rem;
    }
  }
}

.table-card {
  background: white;
  border-radius: .5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
  border-color: $gray-200;

  tr {
    th {
      font-weight: $font-weight-bold;
    }

    td,
    th {
      padding-left: 1.5em;
      padding-right: 1.5em;
    }

    &.active {
      td {
        background: tint-color($primary, 90%);
      }

      &:hover,
      &:focus {
        background: tint-color($primary, 50%);
      }
    }
  }

  thead {
    tr {
      th {
        background: $gray-100;
        border-bottom: 1px solid $gray-200 !important;
        text-transform: capitalize;
      }

      > :first-child {
        border-top-left-radius: .5rem;
      }

      > :last-child {
        border-top-right-radius: .5rem;
      }
    }
  }

  tbody {
    > :last-child {
      td {
        border: 0 !important;
      }
    }

    > :not(:first-child) {
      border-top: 0;
    }
  }
}